<template lang="pug">
  observer.slice--ticker.py-15(:style="{backgroundColor: slice.primary.bg_color, color: slice.primary.text_color}", :threshold="0.05", @visible="animate = true", @hidden="animate = false")
    ticker(:texts="slice.items", :animate="animate")
</template>

<script>
import Ticker from '@/components/Ticker'
import Observer from '@/components/IntersectionObserver'
export default {
  name: 'Slice--Ticker',
  props: ['slice'],
  data () {
    return {
      animate: true
    }
  },
  components: { Ticker, Observer }
}
</script>
