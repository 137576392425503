<template lang="pug">
div
  //- intro card
  intro-card(:style="{zIndex: '99'}")

  //- set .fixed/.z- in parent
  header.app-header.fixed.top-0.left-0.w-full.pt-15.px-15.md-pt-20.md-px-20.select-none(v-if="header.primary", :class="[zIndex, {'app-header--is-info': isInfo}, { 'blend-diff': !isBST }]")
    //- inset frame
    .relative


      //- backdrop
      backdropFilter.absolute.top-0.left-0.w-full( :titleHover="titleHover" :theme="header.primary")
      //- (header type: bar)
      template(v-if="header.slice_type === 'site_header__bar'")
        //- top bar
        .absolute.z-40.top-0.left-0.w-full.text-18.md-text-24.leading-none(id="titlebar", v-show="isPortrait || !loading", :style="barStyle", @mouseenter="onMouseEvent(true)", @mouseleave="onMouseEvent(false)")
          //- height sizer
          .header-row.w-full

          //- section/project titles layer
          transition(name="barbody")
            h1.absolute.top-0.left-0.w-full.flex.items-center.justify-center.header-row-height(v-show="!titleHover && titleActive")
              span.truncate.flex.px-20(:id="'appProjectTitle'", :style="{width: 'fit-content',  display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', paddingTop: '0.15em' }") {{title}}


          //- menu layer
          transition-group(name="barbody")
            nav.absolute.top-0.left-0.w-full.z-20(role="navigation", key="project", v-show="(titleHover || !titleActive)")
              //- Project Menu
              project-menu.header-row-height(v-show="projectMenuActive", :id="'appProjectMenu'")
              //- Home Menu...
              .flex.md-justify-center.justify-between.items-center.w-full.md-w-fit-content(v-show="!projectMenuActive", :id="'appHomeMenu'", :style="{ margin: '0 auto', paddingTop: '0.1em' }")
                router-link.menu-link.header-row-height.z-10.uppercase.rounded-lg.md-px-20.px-10(v-for="(item, i) in header.items", :to="linkResolver(item.link)", :key="i", :style="{width: '8.5em'}")
                  //- texts... (lazy mbl option "Info,Information")
                  div(v-for="(txt, ii) in item.link_label.split(' | ')", :class="{'sm-hidden flex': !ii, 'hidden sm-flex': ii > 0}", :style="{ height: '100%', alignItems: 'center', justifyContent: 'center' }") {{txt}}


        //- notes dropdown
        transition(name="note", @after-enter="expandNote(10)")
          //- fade in bar/color
          aside.absolute.z-20.top-0.left-0.w-full.select-text.rounded-3(v-if="note", :style="{background: note.data.bgcolor, color: note.data.text_color}", v-click-outside="closeNote")
            .h-56
            //- expand note
            transition(name="notebody")
              .absolute.w-full.top-0.left-0.max-h-screen_-40.overflow-y-scroll(v-show="noteExpanded", :style="{background: note.data.bgcolor}")
                div(:style="{transition: noteExpanded ? 'all 100ms 200ms' : 'none'}")
                  prismic-rich-text.pt-20.pl-20.pr-32.pb-85.md-pr-85.md-pb-144.text-16.md-text-24.children-mt-em.list-bullets-lg(:field="note.data.body")

      //- (header type: text)
      template(v-if="header.slice_type === 'site_header__text'")
        .text-24.md-text-25.font-normal(:style="{color: header.primary.text_color}")
          //- mobile/landscape texts
          router-link(to="#info")
            //- texts...
            span(v-for="(text, i) in header.primary.text.split(' | ')", :class="{'md-hidden': !i, 'hidden md-inline': i > 0}") {{ text }}
  </template>

<script>
import { mapState, mapGetters } from "vuex";
import IntroCard from "@/components/IntroCard";
import ProjectMenu from "@/components/ProjectMenu";
import BackdropFilter from "@/components/BackdropFilter";
import ClickOutside from "vue-click-outside";
import linkResolver from "@/plugins/prismic/link-resolver";
import _throttle from "lodash/throttle";
export default {
  name: "AppHeader",
  data() {
    return {
      titleHover: false,
      noteExpanded: false,
      // isClosing: false,
      afterScroll: null,
      lastScroll: 0
      // portraitScrollStopped: false,
      // isScrolling: true // show BST on load
    };
  },
  computed: {
    ...mapState([
      "title",
      "titleTheme",
      "titleActive",
      "projectMenuActive",
      "bus",
      "loading",
      "intro",
      "infoAnim",
      "main",
      "canHover"
    ]),
    ...mapGetters(["isPortrait", "header", "isBST", "allFeedChildren"]),
    isInfo() {
      return this.$route.hash.indexOf("#info") === 0;
    },
    note() {
      return this.$store.state.notes.find(
        note => note.uid === this.$route.hash.replace("#info_", "")
      );
    },
    barStyle() {
      const theme = this.titleTheme;
      return {
        // background: theme[0],
        //- color: theme[1],
        color: theme[1],
        transition: "color 200ms"
      };
    },
    activeMenuIndex() {
      let pos = 0;
      if (this.header.items) {
        pos = this.header.items.findIndex?.(itm => {
          return (
            linkResolver(itm.link) === this.$route.fullPath ||
            (itm.link.type === "info" && this.$route.hash.startsWith("#info"))
          );
        });
      }
      if (pos === -1) pos = 0;
      return pos;
    },
    zIndex() {
      if (this.header && this.header.slice_type === "site_header__text") {
        return "z-20"; // sf: below INFO/PROJECT overlays
      } else {
        return this.isInfo ? "z-30" : "z-50"; // sf: above INFO/PROJECT overlays
      }
    }
  },
  methods: {
    linkResolver,
    onMouseEvent(hover) {
      if (!this.canHover) return; // ios fires
      this.titleHover = hover;
    },
    closeNote() {
      if (!this.note) return;
      this.noteExpanded = false;
      setTimeout(() => {
        this.$router.push({ hash: "info" });
      }, 100);
    },
    closeInfo() {
      this.$store.state.bus.$emit("closeInfo");
    },
    expandNote(delay) {
      setTimeout(() => {
        this.noteExpanded = true;
      }, delay);
    },
    onScroll: _throttle(function() {
      // scroll direction

      clearTimeout(this.afterScroll);
      const scroll = window.scrollY;
      const scrollDir = scroll > this.lastScroll ? "down" : "up";

      // Stop triggering on scroll loop. (500 is magic number)
      if (Math.abs(scroll - this.lastScroll) > 500) {
        this.lastScroll = scroll;
        return;
      }
      this.lastScroll = scroll;

      if (scrollDir === "up") {
        // Show menu on scroll up.
        if (this.titleActive) this.$store.commit("TITLE_INACTIVE");
      } else {
        // show title on scroll down.
        if (!this.titleActive) this.$store.commit("TITLE_ACTIVE");
        // hide title after time.
        this.afterScroll = setTimeout(() => {
          this.$store.commit("TITLE_INACTIVE");
        }, 3000);
      }
    }, 250),
    pauseTitleActive() {
      this.$store.commit("TITLE_INACTIVE");
      window.removeEventListener("scroll", this.onScroll);
      setTimeout(() => {
        window.addEventListener("scroll", this.onScroll);
      }, 300);
    },
    checkRouteisFeedChild() {
      const allFeedChildren = this.allFeedChildren;
      const allFeedChildrenUIDs = allFeedChildren
        .map(child => child.map(itm => itm.primary.project.uid))
        .flat();
      const isFeedChildPage = allFeedChildrenUIDs.some(uid =>
        this.$route.path.includes(uid)
      );

      if (isFeedChildPage) {
        this.$store.commit("PROJECT_MENU_ACTIVE");
      } else {
        this.$store.commit("PROJECT_MENU_INACTIVE");
      }
    }
  },
  watch: {
    $route() {
      this.pauseTitleActive();
      this.checkRouteisFeedChild();
    },
    "$store.state.projects"() {
      this.checkRouteisFeedChild();
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.bus.$on("closeNotes", this.closeNote);
    // console.log(this.header);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  components: { IntroCard, ProjectMenu, BackdropFilter },
  directives: { ClickOutside }
};
</script>

<style>
@import "../style/_settings.css";

/* top bar item style */
.header-row {
  @apply h-45 pt-4 flex items-center justify-center leading-tight relative z-10;
}
@screen md {
  .header-row {
    @apply h-50;
  }
}
@screen xl {
  .header-row {
    @apply h-56;
  }
}

.header-row-height {
  height: 5.3rem;
}
/* @screen md {
  .header-row-height {
    height: 5.3rem;
  }
} */
@screen xl {
  .header-row-height {
    @apply h-56;
  }
}

.app-header {
  /* disable tap higlight */
  -webkit-tap-highlight-color: transparent;
}

.app-header .collapse-transition {
  /*transition-duration: 400ms !important;*/
  /*transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1) !important;*/
}

.intro-card-leave-active {
  transition: opacity 150ms;
}
.intro-card-leave-to {
  opacity: 0;
}

.note-enter-active {
  transition: opacity 100ms;
}
.note-leave-active {
  transition: opacity 100ms;
}
.note-enter,
.note-leave-to {
  opacity: 0;
}

/* NOTEBODY: ENTER */
.notebody-enter-active,
.notebody-leave-active {
  transition: transform 225ms;
  transform-origin: top center;
  transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
}
.notebody-enter {
  transform: scale(1, 0);
}
.notebody-enter > * {
  opacity: 0;
  transform: translateY(-3px);
}
/* NOTEBODY: LEAVE */
.notebody-leave-active {
  transition: transform 225ms;
}
.notebody-leave-to {
  transform: scale(1, 0);
}
.notebody-leave-to > * {
  opacity: 0;
}
/* set > * transition on element (bc clipped off) */

/* bar default */
.barbody-leave-active {
  transition: opacity 150ms;
}
.barbody-enter-active {
  transition: opacity 150ms 100ms;
}
/* bar on info */
/*.app-header--is-info .barbody-leave-active{ transition: opacity 200ms; z-index:20; }*/
/*.app-header--is-info .barbody-enter-active{ transition: opacity 0s; z-index:15; }*/
/* bar enter/leave */
.barbody-enter,
.barbody-leave-to {
  opacity: 0;
}

/* .menu-link.router-link-exact-active {
    backdrop-filter: blur(20px);
    border-radius: 10px;
  } */
</style>
