<script>
export default {
  props: ["slice"]
};
</script>

<template lang="pug">
div.pb-em.flex.flex-col.md-flex-row.flex-wrap.text-24.md-text-48
  .w-full.lg-w-1x2
    prismic-rich-text.info-view__text.children-mt-em.tooltip-hashlinks(:field="slice.primary.left_column")
  .w-full.lg-w-1x2
    prismic-rich-text.mt-20px.lg-mt-0.lg-ml-20px.info-view__text.children-mt-em.tooltip-hashlinks(:field="slice.primary.right_column")
</template>
