<template lang="pug">
#app.leading-tight(:class="appClass", :data-scrolllock="scrollLock", :data-intro="intro")
  //- blur layer
  blur-layer#app__blur.z-20(:class="{'z-40': isProjectOverInfo}", :blur="infoVisible || blur || ($route.name && $route.name.endsWith('__project'))")

  //- header
  app-header

  //- body
  main#app__main
    router-view

  //- info overlay
  transition(name="info", @before-enter="beforeInfoEnter", @after-leave="afterInfoLeave")
    info.fixed.z-20(v-show="infoVisible")

  //- preload other menu pages
  preload
</template>

<script>
import "@/style/_main.css";
import { mapState, mapGetters } from "vuex";
import AppHeader from "@/components/AppHeader";
import Info from "@/views/Info";
import BlurLayer from "@/components/BlurLayer";
import Preload from "@/components/Preload";
import _throttle from "lodash/throttle";
import _get from "lodash/get";

export default {
  name: "App",
  data() {
    return {
      scrollLock: -1,
      scrollY: 0,
      lastTitle: { title: "", theme: [] },
      justReturned: false
    };
  },
  computed: {
    ...mapState([
      "bus",
      "title",
      "titleTheme",
      "loading",
      "intro",
      "blur",
      "main"
    ]),
    ...mapGetters(["isPortrait"]),
    infoVisible() {
      if (this.$store.isBST) return false;
      return (
        (this.$route.hash && this.$route.hash.startsWith("#info")) || false
      );
    },
    appClass() {
      return {
        "h-screen": this.scrollLock > -1 || this.intro,
        "font-sans":
          process.env.VUE_APP_FONT &&
          process.env.VUE_APP_FONT !== "font-sans-sf",
        "font-sans-sf tracking-massive":
          process.env.VUE_APP_FONT &&
          process.env.VUE_APP_FONT === "font-sans-sf"
      };
    },
    isProjectOverInfo() {
      return this.infoVisible && this.$route.path.includes("/projects/");
    }
  },
  methods: {
    // INFO ENTER
    beforeInfoEnter() {
      this.lastTitle = { title: this.title, theme: this.titleTheme };
      this.lockScroll(true);
    },
    // INFO LEAVE:
    afterInfoLeave() {
      this.justReturned = true;
      this.lockScroll(false);
      // this.$store.commit('TITLE_INACTIVE') // maybe disable in case scrolling out
      setTimeout(() => {
        this.$store.commit("SET_TITLE", this.lastTitle.title);
        this.$store.commit("SET_TITLE_THEME", this.lastTitle.theme);
      }, 300); // so doesn't flash

      setTimeout(() => {
        this.justReturned = false;
      }, 2000);
    },
    lockScroll(lock) {
      const y = lock ? window.pageYOffset : this.scrollLock;
      // lock scroll
      if (lock) {
        this.scrollLock = y;
        this.$nextTick(() => this.$el.scrollTo(0, y));
        // unlock
      } else {
        this.scrollLock = -1; // unlocks
        this.$nextTick(() => {
          this.bus.$emit("unlockScroll");
          window.scrollTo(0, y); // jump to saved pos
        });
      }
    },
    // onScroll: _throttle(function () {
    //   this.scrollY = window.pageYOffset
    //   this.bus.$emit('newScrollY')
    // }, 50),
    onResize: _throttle(function() {
      const update = (value, old, emit, commit) => {
        if (value === old) return;
        this.$store.commit(commit, value);
        this.bus.$emit(emit);
      };
      update(window.innerWidth, this.winW, "newWindowWidth", "SET_WIN_W");
      update(window.innerHeight, this.winH, "newWindowHeight", "SET_WIN_H");
    }, 100),
    startAtHome() {
      window.scroll(0, 0);
      if (this.infoVisible) this.$router.replace({ hash: "" });
    },
    bindPrismicRouterLinks() {
      window.addEventListener(
        "click",
        event => {
          // If the clicked element doesn't have the right selector, bail
          if (!event.target.matches("a.prismic-router-link")) return;
          // Don't follow the link
          event.preventDefault();
          // Push link destination to router...
          // * preserve hash for #info overlay:
          const hash = this.$route.hash ? this.$route.hash : "";
          this.$router.push(event.target.pathname + hash);
        },
        false
      );
    },
    onWheel: _throttle(
      function(e) {
        if (this.$store.state.introSettings.type !== "sf") return;
        if (!e.deltaY || this.infoVisible || this.$route.name !== "home")
          return;

        if (this.justReturned) return;

        if (e.deltaY > 30) {
          this.$router.push("#info");
        }
      },
      100,
      { leading: false }
    )
  },
  watch: {
    $route(to, from) {
      if (from.hash && !to.hash) {
        this.justReturned = true;
      }
      if (to.name !== from.name) {
        // unset Info overlay scrollLock
        this.scrollLock = -1;
      }
    }
  },
  created() {
    this.startAtHome();
    this.$store.dispatch("getMain");
    this.$store.dispatch("getFeeds");
    this.$store.dispatch("getProjects");
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    // window.addEventListener('scroll', this.onScroll)
    this.bindPrismicRouterLinks();
    window.addEventListener("wheel", this.onWheel);
  },
  metaInfo() {
    if (!this.main) return;
    const title = this.$prismic.richTextAsPlain(this.main.title);
    const meta = [
      { name: "description", content: this.main.meta_description },
      { property: "og:title", content: title },
      { property: "og:site_name", content: title },
      { property: "og:type", content: "website" }
    ];
    // image ?
    const ogimg = _get(this.main, "meta_image.large.url");
    if (ogimg) meta.push({ property: "og:image", content: ogimg });
    // print
    return {
      title: title,
      meta: meta
    };
  },
  components: { AppHeader, BlurLayer, Preload, Info }
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* transitions */
.info-enter-active,
.projectoverlay-enter-active {
  /* MATCH EASING TO INTRO CARD */
  transition: transform 550ms cubic-bezier(0, 0.55, 0.45, 1); /* https://easings.net/en#easeInOutCirc */
  /* slow end: cubic-bezier(.15,.51,.31,1)*/
}
.info-enter,
.projectoverlay-enter {
  transform: translateY(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 600ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fadefast-enter-active,
.fadefast-leave-active {
  transition: opacity 200ms;
}
.fadefast-enter,
.fadefast-leave-to {
  opacity: 0;
}

.tracking-massive {
  letter-spacing: 0.12em;
}
</style>
