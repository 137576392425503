<template lang="pug">
  observer.svg-anim(v-html="svgCode", :threshold="[0.5]", @ratio="onVisibility", :data-size="size || 'cover'", :log="true")
</template>

<script>
import Observer from '../components/IntersectionObserver'
export default {
  name: 'Slice--SVGanim',
  props: ['svgUrl', 'frameRate', 'size'],
  data () {
    return {
      letters: [],
      svgCode: '',
      animation: null,
      current: 0,
      visible: false
    }
  },
  // computed: {
  //   frameRate () {
  //     return this.slice.primary.frame_rate || 100
  //   }
  // },
  methods: {
    hideAll () {
      clearTimeout(this.animation)
      return this.letters.forEach(el => { el.style.visibility = 'hidden' })
    },
    animate () {
      const i = this.current
      const last = this.letters[i - 1] || this.letters[this.letters.length - 1]
      const make = (letter, vis) => {
        if (!letter) return
        letter.style.visibility = vis
      }
      make(this.letters[i], 'visible')
      make(last, 'hidden')
      // animate next one, recursively
      this.current = this.current + 1 === this.letters.length ? 0 : this.current + 1
      this.animation = setTimeout(() => this.animate(), this.frameRate)
    },
    pause () {
      clearTimeout(this.animation)
    },
    // sortByXY (a, b) {
    //   const box = el => el.getBoundingClientRect()
    //   a = box(a)
    //   b = box(b)
    //   if (a.left < b.left || a.top < b.top) return -1
    //   if (a.left > b.left || a.top > b.top) return 1
    //   return 0
    // },
    setLetters () {
      this.letters = this.$el.querySelectorAll('svg > *') // former: 'svg g > *'
      this.letters = Array.prototype.slice.call(this.letters, 0) // convert into array
      this.letters.reverse()
      // this.letters.sort(this.sortByXY)
    },
    getSVG () {
      fetch(this.svgUrl)
        .then(response => response.text())
        .then(svg => {
          this.svgCode = svg
          this.$nextTick(() => {
            this.setLetters()
            this.hideAll()
          })
        })
    },
    onVisibility (ratio) {
      return ratio > 0.5 ? this.animate() : this.pause()
    }
  },
  mounted () {
    // this.hideAll()
    this.getSVG()
  },
  watch: {
    // visible (vis) {
    //   // console.log('vis', vis)
    //   if (vis) return this.animate()
    //   this.pause()
    // },
    '$route' (to) {
      if (to.hash.startsWith('#info')) this.pause()
      else if (this.visible) setTimeout(() => this.animate(), 300)
    }
  },
  components: { Observer }
}
</script>

<style>
.svg-anim > svg {
  display: block;
  pointer-events: none; /* hide title hover */
}
.svg-anim[data-size="cover"] {
  position: absolute;
  height:100%;
  width:100%;
  & > svg{
    position:absolute;
    width:100%;
    height:100%;
  }
}

.svg-anim[data-size="w-auto"] > svg{
  width:100% !important; /* override w/h attr */
  height:auto !important; /* override w/h attr */
}
</style>
