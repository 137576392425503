<template lang="pug">
article#info.top-0.left-0.h-screen.overflow-y-scroll.w-full.scrolling-touch
  //- (page body)
  template(v-if="main")
    .pt-20px.pb-4em.min-h-screen.px-15.md-px-20(ref="body", :style="{backgroundColor: main.info_bgcolor, color: main.info_text_color, ...customHover}")
      //- slices...
      template(v-for="(slice, i) in main.info")
        //- (section slice)
        template(v-if="slice.slice_type === 'gap'")
          div.text-24.md-text-48(:style="gapStyle(slice.primary.gap)")
        template(v-if="slice.slice_type === 'section'")
          observer.pb-em.text-24.md-text-48(:threshold="0.3", @visible="setTitle(slice.primary.section_title)", :key="i")
            //- body
            prismic-rich-text.info-view__text.children-mt-em.tooltip-hashlinks(:field="slice.primary.body")
        //- (contact links)
        template(v-if="slice.slice_type === 'contact'")
          ul.text-24.md-text-48.pb-em.list-reset
            li(v-for="item in slice.items")
              labeled-link(:label="item.link_label", :link="item.link")

        //- (hover-links)
        slice-hover-links.pb-em.text-24.md-text-48(v-if="slice.slice_type === 'hover-links'", :title="slice.primary.section_title", :items="slice.items" :hoverColor="main.text_hover_color")

        //- (Two Column Text)
        template(v-if="slice.slice_type === 'two_column_text'")
          observer.pb-em.flex.flex-col.md-flex-row.flex-wrap.text-24.md-text-48(:threshold="0.3", @visible="setTitle(slice.primary.section_title)", :key="i")
            .w-full.lg-w-1x2
              prismic-rich-text.info-view__text.children-mt-em.tooltip-hashlinks(:field="slice.primary.left_column")
            .w-full.lg-w-1x2
              prismic-rich-text.mt-20px.lg-mt-0.lg-ml-20px.info-view__text.children-mt-em.tooltip-hashlinks(:field="slice.primary.right_column")

      //- footer
      footer
        .md-flex.justify-between.text-24.md-text-48(v-if="isVisible")
          //- subscribe form
          subscribe-form.md-w-1x2(v-if="showSubscribe")
          //- credits btn
          h6.mt-em.md-w-1x2.md-text-right.md-mt-0(v-if="main.credits[0].text")
            button.pointer.hover-skew.transition-skew(@click="credits = !credits") Credits
        //- (credits dropdown)
        el-collapse-transition
          .absolute.w-full.left-0.z-20(v-show="credits")
            prismic-rich-text.text-12.md-text-16.pt-45.px-20.pb-20.children-mt-em(:field="main.credits", :style="{backgroundColor: main.info_bgcolor}")
      //- ticker
      //- ticker.absolute.bottom-0.left-0.pb-3(:animate="!infoAnim")

  //- empty area, triggers auto close
  //- ios: ht should be taller than window
  observer#info-end.h-screen.mt-3(:threshold="0.35", @visible="autoClose = true", @hidden="autoClose = false", :style="{height: 1.2 * winH + 'px'}")
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Observer from "@/components/IntersectionObserver";
import LabeledLink from "@/components/LabeledLink";
import SliceHoverLinks from "@/slices/SliceHoverLinks";
import Ticker from "@/components/Ticker";
import SubscribeForm from "@/components/SubscribeForm";
import throttle from "lodash/throttle";

export default {
  name: "Info",
  data() {
    return {
      credits: false,
      autoClose: false,
      autoCloseScroll: null,
      afterScroll: null
    };
  },
  computed: {
    ...mapState(["main", "bus", "titleActive", "winH", "canHover"]),
    isVisible() {
      return this.$route.hash.indexOf("#info") === 0;
    },
    showSubscribe() {
      return this.$store.state.introSettings.type !== "sf";
    },
    customHover() {
      return {
        "--color-hover": this.main.text_hover_color
      };
    }
  },
  methods: {
    ...mapMutations({ setTitle: "SET_TITLE" }),
    close() {
      this.$router.push({ hash: "" });
    },
    closeNotes() {
      if (this.$route.hash) this.bus.$emit("closeNotes");
    },
    gapStyle(gap) {
      return {
        height: gap === "4em" ? "3em" : "1em" // Sorry for confusion 4em is now 3em
      };
    },
    onScroll: throttle(function() {
      this.closeNotes();
      // show title
      if (!this.titleActive) this.$store.commit("TITLE_ACTIVE");
      // (auto) close info
      clearTimeout(this.afterScroll);
      const bodyHt = this.$refs.body.offsetHeight;
      if (!bodyHt) return;

      const hitBottom = this.$el.scrollTop >= bodyHt - 2;
      if (hitBottom) {
        // close
        this.close();
        this.$el.removeEventListener("scroll", this.onScroll);
      } else if (this.autoClose) {
        // auto-close after scroll
        this.afterScroll = setTimeout(() => this.scrollClose(400), 101);
      } else {
        // hide title after scroll
        this.afterScroll = setTimeout(
          () => this.$store.commit("TITLE_INACTIVE"),
          500
        );
      }
    }, 50),
    scrollClose(speed = 800) {
      if (this.autoCloseScroll) return;
      this.autoCloseScroll = this.$scrollTo("#info-end", {
        duration: speed,
        container: "#info",
        cancelable: true,
        onDone: () => {
          this.autoCloseScroll = null;
        }
      });
    },
    bindReadMoreLinks() {
      if (!this.canHover) return;
      // move
      const onLinkHover = e => {
        const rect = e.target.getBoundingClientRect();
        const left = parseInt(e.pageX - rect.left);
        const top = parseInt(e.pageY - rect.top);
        const tip = e.target.querySelector("span");
        return (
          tip &&
          requestAnimationFrame(() => {
            tip.style.transform = `translate(calc(${left}px - 50%), calc(${top}px - 150%))`;
          })
        );
      };
      // bind
      const links = this.$el.querySelectorAll(
        '.tooltip-hashlinks a[href*="#"]'
      );
      links.forEach(el => {
        let tip = el.querySelector("span");
        if (!tip) {
          // add tip, bind mousemove
          tip = document.createElement("span");
          el.append(tip);
          el.addEventListener("mousemove", onLinkHover);
        }
      });
    },
    onVisible() {
      if (!this.main) return;
      // fetch notes
      this.$store.dispatch("getNotes");
      // update title
      this.setTitle(this.main.info[0].primary.section_title);
      this.$store.commit("SET_TITLE_THEME");
      // scroll info to top
      this.$el.scroll(0, 0);
      // bind blur layer to scroll
      this.$root.$emit("bindBlurLayerToOverlayScroll", this.$el);
      // delay bind scroll so title doesn't flash
      setTimeout(() => {
        this.$el.addEventListener("scroll", this.onScroll);
      }, 200);
      // watch for closeInfo
      this.bus.$on("closeInfo", this.scrollClose);
      // setup links
      this.bindReadMoreLinks();
    },
    onHidden() {
      this.bus.$off("closeInfo", this.scrollClose);
      this.$el.removeEventListener("scroll", this.onScroll);
      // unbind blur layer
      this.$root.$emit("unbindBlurLayerToOverlayScroll", this.$el);
    }
  },
  destroyed() {
    this.onHidden();
  },
  watch: {
    isVisible(vis) {
      if (vis) {
        this.onVisible();
      } else {
        this.onHidden();
      }
    }
  },
  components: { Observer, LabeledLink, Ticker, SubscribeForm, SliceHoverLinks }
};
</script>

<style lang="postcss">
@import "../style/_settings";

/* "Read More" tooltips */
.tooltip-hashlinks a[href*="#"] {
  position: relative;
  &:hover span {
    opacity: 1;
  }
  & span {
    pointer-events: none;
    opacity: 0;
    transition: opacity 100ms;
    background: rgba(255, 255, 255, 0.9);
    font-size: 1.45rem;
    padding: 0.6em 0.7em 0.5em;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.125em;
    box-shadow: var(--shadow);
    white-space: nowrap;
    &:before {
      content: "Read More";
    }
  }
}
#info {
  word-break: break-word;
}
#info a:hover,
#info button:hover,
#info .labeled-link:hover {
  color: var(--color-hover);
}
</style>
