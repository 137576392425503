<template lang="pug">
  //- pad + neg margin for overflow-x-hidden (won't clip y)
  observer.slice--glyph-table.relative.w-full.overflow-x-hidden.py-1000.-my-1000.pointer-events-none(ref="obsv", @visible="render = true", :threshold="0.1")
    //- table(s)
    ul.bg-black.py-px.bg-inherit.pointer-events-auto.px-15.md-px-20(v-for="table in tables", :style="tableStyle")
      //- cells
      li.block.w-1x6.md-w-1x12.lg-w-1x18.xl-w-1x27.float-left.border.border-inherit.bg-inherit.-ml-px.-mb-px(v-for="item in table.items")
        //- absolute pos in CSS below...
        img.block.w-full(v-if="render", :src="item.glyph.url", :alt="item.glyph.alt || 'Typeface Glyph'")
</template>

<script>
import { mapState } from "vuex";
import _get from "lodash/get";
import Observer from "@/components/IntersectionObserver";
export default {
  name: "Slice--GlyphTable",
  props: ["slice"],
  data() {
    return {
      tables: [],
      render: false,
      afterScroll: null
    };
  },
  computed: {
    ...mapState(["bus"]),
    tableStyle() {
      return {
        // width: `calc(100% + ${cols}px)`,
        backgroundColor: this.slice.primary.bg_color,
        borderColor: "rgba(255,255,255,0.1)" // this.slice.primary.table_border_color
      };
    }
  },
  methods: {
    getTable(id) {
      return this.$prismic.client.getByID(id).then(doc => {
        this.tables = _get(doc, "data.body") || [];
      });
    },
    renderOnFirstScroll() {
      this.render = true;
    }
  },
  watch: {
    render(rnd) {
      if (rnd) this.$refs.obsv.unobserve();
    }
  },
  created() {
    this.getTable(this.slice.primary.glyph_table_link.id);
  },
  components: { Observer }
};
</script>

<style lang="postcss">
.slice--glyph-table ul:after {
  content: "";
  display: block;
  clear: both;
}

/* HOVER/TOUCH:ACTIVE EFFECT */
.slice--glyph-table li {
  /*transition: transform .1s ease;*/
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0);
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    transition: width 80ms 0s, transform 80ms 0s, border-color 0s,
      background-color 0ms 80ms;
    /*pointer-events: none;*/
  }
}
.slice--glyph-table li:hover {
  /*position: relative;*/
  z-index: 10;
  /*transition: transform .1s 40ms ease;
  transform: scale(3) translate(-0.1px, -0.1px);
  transform-origin: center center;*/
  & img {
    width: calc(300% + 6px);
    background-color: rgb(0, 0, 0, 1);
    max-width: none;
    border-color: inherit;
    transition: width 80ms 40ms, transform 80ms 40ms, border-color 80ms 40ms,
      background-color 0ms 0ms;
  }
}

/*
1. TABLE WIDTHS
   (account for negative margin on cells)
2. CELL HOVER TRANSF-ORIGIN
   (left/right edge anim. from edge)
*/
.slice--glyph-table {
  & ul {
    width: calc(100% + 6px);
  }
  & li {
    &:nth-child(6n + 1) {
      /*transform-origin: center left;*/
      & img {
        left: 0;
        transform: translate(0, -50%);
      }
    }
    &:nth-child(6n) {
      /*transform-origin: center right;*/
      & img {
        left: auto;
        right: 0;
        transform: translate(0, -50%);
      }
    }
  }
}

/* --bkpt-md */
@media (min-width: 600px) {
  .slice--glyph-table {
    & ul {
      width: calc(100% + 12px);
    }
    & li {
      /* reset */
      &:nth-child(6n + 1),
      &:nth-child(6n) {
        /*transform-origin: center center;*/
        & img {
          left: 50%;
          right: auto;
          transform: translate(-50%, -50%);
        }
      }
      /* 9 cols */
      &:nth-child(12n + 1) {
        /*transform-origin: center left;*/
        & img {
          left: 0;
          transform: translate(0, -50%);
        }
      }
      &:nth-child(12n) {
        /*transform-origin: center right;*/
        & img {
          left: auto;
          right: 0;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

/* --bkpt-lg */
@media (min-width: 960px) {
  .slice--glyph-table {
    & ul {
      width: calc(100% + 18px);
    }
    & li {
      /* reset */
      &:nth-child(6n + 1),
      &:nth-child(6n),
      &:nth-child(12n + 1),
      &:nth-child(12n) {
        /*transform-origin: center center;*/
        & img {
          left: 50%;
          right: auto;
          transform: translate(-50%, -50%);
        }
      }
      /* 14 cols */
      &:nth-child(18n + 1) {
        /*transform-origin: center left;*/
        & img {
          left: 0;
          transform: translate(0, -50%);
        }
      }
      &:nth-child(18n) {
        /*transform-origin: center right;*/
        & img {
          left: auto;
          right: 0;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

/* --bkpt-xl */
@media (min-width: 1441px) {
  .slice--glyph-table {
    & ul {
      width: calc(100% + 27px);
    }
    & li {
      /* reset */
      &:nth-child(6n + 1),
      &:nth-child(6n),
      &:nth-child(12n + 1),
      &:nth-child(12n),
      &:nth-child(18n + 1),
      &:nth-child(18n) {
        /*transform-origin: center center;*/
        & img {
          left: 50%;
          right: auto;
          transform: translate(-50%, -50%);
        }
      }
      /* 27 cols */
      &:nth-child(27n + 1) {
        /*transform-origin: center left;*/
        & img {
          left: 0;
          transform: translate(0, -50%);
        }
      }
      &:nth-child(27n) {
        /*transform-origin: center right;*/
        & img {
          left: auto;
          right: 0;
          transform: translate(0, -50%);
        }
      }
    }
  }
}
</style>
