<script>
export default {
  props: ["slice", "sliceNo"],
  methods: {
    gapStyle(gap) {
      return {
        height: gap === "4em" ? "3em" : "1em" // Sorry for confusion 4em is now 3em
      };
    }
  }
};
</script>

<template lang="pug">
div.text-24.md-text-48(:style="gapStyle(slice.primary.gap)", :class="{'pt-15 md-pt-20': sliceNo == 0}")
</template>
