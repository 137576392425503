<template lang="pug">
  .slice--split.relative
    //- bg layer
    figure.absolute.h-full.w-full.top-0.left-0.flex(:class="{'justify-end': isLeft}")
      //- sticky bg
      slice-bg.sticky.w-1x2.h-screen.top-0(:body="slice.primary", :byHeight="true")
    //- content layer
    section.flex.-mb-px(:class="{'flex-row-reverse': isLeft}")
      //- base type graphic
      .w-1x2.relative.flex.items-center
        figure.min-h-screen.px-20.py-45.w-full.relative.flex.items-center
          template(v-if="slice.primary.animation_frame_rate")
            svg-animation.w-full(:svgUrl="slice.primary.svg_graphic.url", :frameRate="slice.primary.animation_frame_rate", size="w-auto")
          template(v-else)
            img.block.w-full(v-if="slice.primary.svg_graphic", :src="slice.primary.svg_graphic.url")
      //- slice items
      .w-1x2.relative
        figure.min-h-screen.px-20.relative.flex.items-center.justify-center(v-for="(item, i) in slice.items", :style="{backgroundColor: item.bg_color}", :class="{'sticky top-0': i === slice.items.length - 1}")
          //- svg graphic
          template(v-if="item.animation_frame_rate")
            svg-animation.relative.z-10.w-full(:svgUrl="item.svg_graphic.url", :frameRate="item.animation_frame_rate", size="w-auto")
          template(v-else)
            img.w-full.block.relative.z-10(v-if="item.svg_graphic.url", :src="item.svg_graphic.url")
          //- bg
          slice-bg.absolute.overlay(:body="item")
</template>

<script>
import SliceBg from "@/components/SliceBg";
import svgAnimation from "@/components/SVGAnimation";
export default {
  name: "Slice--Split",
  props: ["slice"],
  computed: {
    isLeft() {
      return this.slice.primary.format === "left";
    }
  },
  components: { SliceBg, svgAnimation }
};
</script>

<style></style>
