<template lang="pug">
.fixed.w-full.h-full.top-0.left-0.overflow-hidden(ref="overlay", v-if="visible", :style="{height: winH + 'px', background: settings.bgColor, cursor: 'wait' }")
  slot
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "IntroCard",
  props: ["settings"],

  // init
  created() {
    this.setupIntro();
  },

  // run intro once site loading is complete (main image content loads)
  watch: {
    loading(loading) {
      if (!loading) this.runIntro();
    }
  },

  data() {
    return {
      visible: true
    };
  },

  computed: {
    ...mapState(["intro", "loading", "winH"]),
    ...mapGetters(["isPortrait"])
    // introEnabled () {
    //   return !this.isPortrait // && process.env.VUE_APP_INTRO_CARD
    // },
  },

  methods: {
    setupIntro() {
      // card covers content so skip blur and image loading
      this.$store.commit("END_LOADING");
      this.$store.commit("BLUR_OFF");

      // skip on portrait devices
      // if (!this.introEnabled) {
      //   // un-render intro card altogether
      //   this.visible = false
      //   return
      // }

      // set initial card height based on screen
      this.cardHt = this.winH + "px";
      this.$emit("cardHt", this.cardHt);
    },

    async runIntro() {
      if (!this.intro) return;

      // helper
      const wait = time =>
        new Promise(resolve => setTimeout(() => resolve(), time));

      // begin!

      await wait(1000);

      // SKIP INTRO SEQUENCE ??? ==========================
      // if (!this.introEnabled) {
      //  return this.skipIntro()
      // }

      this.$el.style.transition =
        "max-height 550ms cubic-bezier(0, 0.55, 0.45, 1)";
      this.$el.style.maxHeight = this.winH + "px";
      await wait(100);

      this.$store.commit("LEAVE_INTRO");

      // animate!
      this.$el.style.maxHeight = "0px";

      // wait for animation...
      await wait(550);

      // unlock scroll
      this.$store.commit("END_INTRO");
    }
  }
};
</script>
