<template lang="pug">
section.project-feed(v-if="feed")
  //- (child projects route as overlay)
  transition(name="projectoverlay")
    router-view(:key="$route.path")

  //- (slices... lazy loaded)
  template(v-for="(slice, i) in feed.data.body")
    //- (slice type: project)
    template(v-if="slice.slice_type === 'project'")
      section.project-feed__project.relative
        //- (lazy load content)
        template(v-if="load.includes(i)")
          project-body(:doc="getProjectDoc(slice.primary.project)", :index="i", @ending="loadProject(i + 1)", @mounted="measure(50)", :fadeEnd="feed.data.body.length > 1", :key="i")

        //- (optional link)
        //- * always print for SEO
        template(v-if="slice.primary.link && slice.primary.link.link_type !== 'Any'")
          //- (link: doc/project)
          template(v-if="slice.primary.link.link_type === 'Document'")
            //- assume it's a project, and properly nest into this project-feed
            router-link.absolute.overlay(:to="`/${ $route.params.uid || 'projects' }/${slice.primary.link.uid}`")
              .sr-only Open Project

          //- (link: other)
          template(v-else)
            prismic-link.absolute.overlay(:field="slice.primary.link")
              .sr-only Open

  //- SEO - render text (since projects are lazyloaded via `load` array)
  seo-feed-text(:projects="feedProjects")

  //- (repeat first project for scroll looping - if enabled)
  template(v-if="loopEnabled && feedProjects[0]")
    project-body(ref="loopEl", :doc="feedProjects[0]", :loopGap="true")

</template>

<script>
import { mapState, mapGetters } from "vuex";
import ProjectBody from "@/components/ProjectBody";
import SeoFeedText from "@/components/SEOFeedText";
import _throttle from "lodash/throttle";
export default {
  name: "ProjectFeed",
  components: { ProjectBody, SeoFeedText },
  props: ["uid"],
  data() {
    return {
      load: [0],
      slices: [],
      loopActive: !this.$route.hash.startsWith("#info"),
      afterScroll: null,
      topBarThemeWayPts: null
    };
  },
  computed: {
    ...mapState(["main", "projects", "winH", "bus", "intro"]),
    ...mapGetters(["isPortrait"]),
    feed() {
      const uid = this.uid || this.main?.home_feed?.uid; // default home

      return this.$store.state.feeds.find(feed => feed.uid === uid);
    },
    feedProjects() {
      let docs = [];
      if (this.feed) {
        docs = this.feed.data.body.map(slice => {
          const doc = slice.primary.project;
          return this.projects.find(prj => doc && doc.id === prj.id);
        });
      }

      return docs;
    },
    loopEnabled() {
      return this.feed?.data?.scroll_loop !== false;
    }
  },
  methods: {
    getProjectDoc(ref) {
      return this.projects.find(prj => ref && ref.id === prj.id);
    },
    toggleLoop(delay = 0) {
      // Enable / Disable loop on scroll helper
      setTimeout(() => {
        this.loopActive = !this.loopActive;
      }, delay);
    },
    measure(delay = 0) {
      // Function to fire once DOM has changed dimensions in some manner
      if (this.intro) return;
      setTimeout(() => {
        this.loopEnabled = !this.loopEnabled;
      }, delay);
    },
    measure(delay = 0) {
      // Function to fire once DOM has changed dimensions in some manner
      if (this.intro) return;
      setTimeout(() => {
        this.setSnapSlices();
        this.setTopBarThemeWayPts();
      }, delay);
    },
    loadProject(i) {
      // Render a project in DOM
      if (this.load.includes(i)) return;
      this.load.push(i);
    },
    onScroll() {
      // functions that occur on scroll
      this.loopOnScroll();
      this.changeTopBarTheme();
      this.onAfterScroll();

      const y = window.pageYOffset;
      const projects = document.querySelectorAll(".project-body");
      const offsetTops = [...projects].map(el => el.parentElement.offsetTop);
      offsetTops.pop();
      offsetTops.push(document.body.scrollHeight);
      const projectsBelowY = offsetTops.filter(offset => offset > y);
      const indexOfFirstProjectBelowY = offsetTops.indexOf(projectsBelowY[0]);
      const currentProjectTitle = projects[
        indexOfFirstProjectBelowY - 1
      ].getAttribute("data-title");

      if (this.$store.state.title !== currentProjectTitle) {
        this.$store.commit("SET_TITLE", currentProjectTitle);
      }
    },
    changeTopBarTheme: _throttle(function() {
      // Change Top Bar Theme colors based on scroll points of Slice--TopBarTheme.vue elements
      const y = window.pageYOffset + 40; // with offset
      if (!this.topBarThemeWayPts) return;
      // copy pts, reverse and find first (lowest)
      const active =
        this.topBarThemeWayPts
          .slice()
          .reverse()
          .find(pt => y >= pt.y) || {};
      requestAnimationFrame(() =>
        this.$store.commit("SET_TITLE_THEME", active.theme)
      );
    }, 50),
    setTopBarThemeWayPts() {
      if (!this.$el) return;
      // Calculate position of top bar theme changes (Slice--TopBarTheme.vue els)
      const y = window.pageYOffset;
      const els = document.querySelectorAll(".slice-top-bar-theme");
      this.topBarThemeWayPts = Array.prototype.slice.call(els).map(el => {
        return {
          y: parseInt(el.getBoundingClientRect().top + y),
          theme: JSON.parse(el.getAttribute("data-topbar-theme"))
        };
      });
    },
    onAfterScroll() {
      clearTimeout(this.afterScroll);
      this.afterScroll = setTimeout(() => {
        this.snapToSlice();
      }, 400);
    },
    loopOnScroll() {
      if (!this.loopEnabled) return false;
      // Scroll position loops back to beginning
      // some browsers support carrying the intertia :)
      const y = window.pageYOffset;
      const scrollHt = document.body.scrollHeight;
      // trigger: height of scroll body (minus) loop el height
      const triggerPt = scrollHt - this.$refs.loopEl.$el.offsetHeight;
      if (y > triggerPt) {
        // if y exceeds this calculation will jump it to the proper place
        return window.scroll(0, y % triggerPt);
      } else if (y === triggerPt) {
        // if y reads exact, jump to 0 pos
        return window.scroll(0, 0);
      }
    },
    setSnapSlices() {
      // TODO - maybe just use a simple class on the slice components... .slice--scroll-snap
      let types = ["full", "split", "full_b", "full_sticky_fore"];
      types = types.map(t => `.project-body__slice[data-slice-type=${t}]`);
      const els = document.querySelectorAll(types);
      this.slices = els && Array.prototype.slice.call(els);
    },
    snapToSlice() {
      /**
       * Function to snap the window to certain slices
       **/
      let slice;
      const range = 120;
      let debug = this.$route.query.debug;
      const disabled =
        this.isPortrait || process.env.VUE_APP_SCROLLSNAP === "false";
      if (disabled) return;

      // Snap to Top of Slice ?
      slice = this.slices.find((slice, i) => {
        const top = Math.abs(slice.getBoundingClientRect().top);
        // in range and not already at pos (de-dupe)
        if (top <= range && top > 0) {
          if (debug)
            console.log(
              "snapTop:",
              i,
              "absPos:",
              top,
              "slicesLength:",
              this.slices.length
            );
          return true;
        }
      });
      if (slice) return this.$scrollTo(slice, 400, { cancelable: true });
      // Snap to Bottom of Slice ?
      slice = this.slices.find((slice, i) => {
        const box = slice.getBoundingClientRect();
        // Bottom edge is in range (exclude those above window), also de-dupe first slice
        if (
          box.bottom > 0 &&
          Math.abs(box.bottom - parseInt(this.winH)) <= range &&
          box.top > 0
        ) {
          if (debug)
            console.log(
              "snapBottom:",
              i,
              "bttm: ",
              box.bottom,
              "abs:",
              Math.abs(box.bottom - parseInt(this.winH)),
              "winH",
              this.winH
            );
          return true;
        }
      });
      if (slice)
        return this.$scrollTo(slice, 400, {
          cancelable: true,
          offset: slice.offsetHeight - this.winH
        });
    }
  },
  watch: {
    $route(to, from) {
      const isInfo = to.hash.startsWith("#info");
      if (isInfo) {
        // on Info overlay
        this.toggleLoop(0); // disable
        window.removeEventListener("scroll", this.onScroll);
      } else {
        // after Info overlay
        this.toggleLoop(150); // enable
        setTimeout(() => window.addEventListener("scroll", this.onScroll), 300);
      }
      if (to.params.uid !== from.params.uid) {
        window.scrollTo({ top: 0 });
      }
    },
    intro() {
      this.measure(100);
    },
    projects() {
      this.$nextTick(() => this.setSnapSlices());
    }
  },
  created() {
    this.$store.commit("SET_TITLE_THEME");
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.measure();
    window.addEventListener("scroll", this.onScroll);
    this.bus.$on("newWindowWidth", this.measure);
    this.bus.$on("newWindowHeight", this.measure);
    this.bus.$on("unlockScroll", this.measure);
    this.bus.$on("landingCollapsed", this.measure);
    this.bus.$on("newTopBarThemeWayPt", this.measure);
    this.bus.$on("imgloaded", this.measure);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
    this.bus.$off("newWindowWidth", this.measure);
    this.bus.$off("newWindowHeight", this.measure);
    this.bus.$off("unlockScroll", this.measure);
    this.bus.$off("landingCollapsed", this.measure);
    this.bus.$off("newTopBarThemeWayPt", this.measure);
    this.bus.$off("imgloaded", this.measure);
  }
};
</script>
