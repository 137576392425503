<template lang="pug">
.w-full.overflow-x-scroll.overflow-y-hidden.scrollbar-hide.h-full.cursor-grab.will-change-scroll.select-none.transform(:style="{lineHeight: 0, background}", ref="container", @mousedown="handleDown", @mousemove="handleMove", @mouseup="handleUp", @mouseleave="handleLeave")
  .inline-flex(ref="wrapper", style="height:101%")
    .relative.will-change-margin(v-for="(item, index) in thumbs", :style="getStyle(item, index)")
      resp-img.overlay.object-cover(:src="getSrc(item)", :byHeight="true", draggable="false")
</template>

<script>
import RespImg from "../../components/RespImg.vue";
import { scrollIntoView } from "seamless-scroll-polyfill";
// import { scrollIntoView } from 'seamless-scroll-polyfill'

let transformDuration = 600;

function easeOutBack(x) {
  const c1 = 1.70158;
  const c3 = c1 + 1;

  return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
}
function sleep(ms = 300) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  name: "CarouselThumbnails",
  props: [
    "items",
    "currentSlide",
    "instantMode",
    "background",
    "visible",
    "parentActive"
  ],
  components: { RespImg },
  data() {
    return {
      dragging: false,
      pos: {
        x: 0,
        left: 0
      },

      totalWidth: 0,
      wrapperWidth: 0,
      padding: 16,
      transformDuration: transformDuration,
      startSlide: 0,
      activeThumbSlide: 0,
      scrollTimeout: null,
      isResting: false,
      thumbs: [],
      thumbBreaks: [],
      isAnimating: false,
      introScrolled: false,
      changeFromParent: -1,
      changeHere: false,
      scrolling: false,
      scrollInterval: null,
      scrollLeft: 0
    };
  },
  mounted() {
    window.addEventListener("resize", this.init);
    this.$refs.container.addEventListener("scroll", this.onScroll);
    this.init();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.init);
    this.$refs.container.removeEventListener("scroll", this.onScroll);
  },
  watch: {
    activeThumbSlide(val) {
      if (this.parentActive) return;
      if (val !== this.changeFromParent && !this.instantMode) {
        // console.log('id mismatch?', val, this.changeFromParent)
        return;
      }

      if (this.instantMode) this.$emit("scroll-change", val);
    },
    currentSlide(val) {
      // console.log('thumb changed,,,', val, this.parentActive, this.changeFromParent, this.instantMode)
      if (this.parentActive) {
        this.changeFromParent = val;
      }
      if (!this.instantMode) {
        const real = val % this.items.length;
        this.slideToClosest(real);
      }
    },
    leavingIntro(val) {
      if (val) {
        const real = this.currentSlide % this.items.length;
        this.slideToClosest(real);
      }
    }
  },
  computed: {
    leavingIntro() {
      return this.$store.state.leavingIntro;
    }
  },
  methods: {
    async slideToClosest(index) {
      if (!this.leavingIntro) return;

      if (this.parentActive) {
        index = this.changeFromParent % this.items.length;
      }

      const difference = index - this.activeThumbSlide;
      // this.isResting = true

      // Loop fix.
      const isLoop = Math.abs(difference) === this.items.length - 1;

      if (isLoop) {
        requestAnimationFrame(() => {
          const loopDirection = Math.sign(difference);
          this.$refs.container.scrollLeft += this.totalWidth * loopDirection;
        });
      }

      await sleep(16);

      // Loop padding style fix...
      this.transformDuration = isLoop ? 0 : transformDuration;

      // Get to closest.
      let scrollAmount = this.loopStart + this.thumbBreaks[index];
      // if (this.isResting) scrollAmount += this.padding
      scrollAmount += this.padding;

      requestAnimationFrame(() => {
        this.isAnimating = true;
        this.animatedScroll(scrollAmount, isLoop);
        // this.animate()
        this.activeThumbSlide = index;
      });

      setTimeout(() => {
        this.$emit("moved");
        this.isAnimating = false;
        this.isResting = true;
      }, 300);
    },

    onScrollRest() {
      this.slideToClosest(this.activeThumbSlide);
      // console.log('scroll rested...')
      if (!this.parentActive) {
        this.$emit("moved");
      }
    },
    onScroll(e) {
      e.preventDefault();

      if (!this.instantMode) {
        // console.log('instant...')
        this.$emit("scroll-start");
      }

      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
      if (!this.isAnimating) {
        this.scrollTimeout = setTimeout(this.onScrollRest, 222);
      }

      if (!this.isAnimating) this.isResting = false;

      const container = this.$refs.container;
      const startScroll = this.loopStart;
      const scrollAmount =
        container.scrollLeft -
        startScroll -
        this.measureThumb(this.items[this.activeThumbSlide]);

      if (!this.isAnimating) {
        const { thumbs, measureThumb, totalWidth } = this;
        const firstThumbWidth = measureThumb(thumbs[0]);
        if (scrollAmount >= totalWidth - firstThumbWidth)
          container.scrollLeft -= totalWidth;
        if (scrollAmount < firstThumbWidth * -1)
          container.scrollLeft += totalWidth;
        this.findActiveThumb();
      }
    },

    handleDown(e) {
      if (this.parentActive) {
        e.preventDefault();
        return;
      }
      this.resting = false;
      this.changehere = true;
      this.dragging = true;
      this.pos.x = e.clientX;
      this.pos.left = this.$el.scrollLeft;
    },
    handleMove(e) {
      if (!this.dragging || this.parentActive) return;

      const dx = e.clientX - this.pos.x;
      this.$el.scrollLeft = this.pos.left - dx;
    },
    handleUp() {
      this.dragging = false;
    },
    handleLeave() {
      this.dragging = false;
    },

    getSrc(item) {
      if (item.media?.url) return item.media.url;
      else if (item.video) {
        return (
          process.env.VUE_APP_CLOUDINARY_URL + `/video/upload/${item.video}.jpg`
        );
      }
      return "";
    },
    async init() {
      this.totalWidth = this.measureThumbs();
      this.setWrapperWidth();
      this.createLoop();

      await this.nextTick;
      this.$refs.container.scrollLeft = this.loopStart + this.thumbBreaks[0];

      this.isResting = true;
    },
    createLoop() {
      const { wrapperWidth, totalWidth } = this;
      const remainingSpace = wrapperWidth - totalWidth;
      const loops = Math.floor(remainingSpace / totalWidth);
      const centerLoopIndex = Math.floor(loops / 2);
      this.loopStart = centerLoopIndex * totalWidth - window.innerWidth * 0.5;
      this.startSlide = centerLoopIndex * this.items.length;
      this.thumbs = Array.from({ length: loops }, () => this.items).flat();
    },
    setWrapperWidth() {
      this.wrapperWidth =
        this.totalWidth + Math.max(this.totalWidth, window.innerWidth) * 3;
      if (this.$refs.wrapper)
        this.$refs.wrapper.style.width = this.wrapperWidth + "px";
    },
    findActiveThumb() {
      const scrollAmount =
        (this.$refs.container.scrollLeft + window.innerWidth * 0.5) %
        this.totalWidth;
      const closest = this.thumbBreaks.reduce(function(prev, curr) {
        return Math.abs(curr - scrollAmount) < Math.abs(prev - scrollAmount)
          ? curr
          : prev;
      });
      this.activeThumbSlide = this.thumbBreaks.indexOf(closest);
    },

    animatedScroll(scrollAmount, loop) {
      const container = this.$refs.container;
      const startY = container.scrollLeft;
      const difference = scrollAmount - startY;

      container.scrollTo({
        left: startY + difference,
        behavior: loop ? "auto" : "smooth"
      });
    },
    measureThumbs() {
      let totalWidth = 0;
      this.items.forEach(item => {
        const thumbWidth = this.measureThumb(item);
        this.thumbBreaks.push(Math.round(totalWidth + thumbWidth / 2));
        totalWidth += thumbWidth;
      });
      return totalWidth;
    },
    measureThumb(item) {
      const wrapperHeight = this.$refs.wrapper.offsetHeight;
      if (!item || !item.media || !item.media.url) return wrapperHeight;

      const { width, height } = item.media;
      const ratio = width / height;
      const thumbWidth = ratio * wrapperHeight;
      return thumbWidth;
    },
    isSlideActive(index) {
      // all matching
      // const l = this.items.length
      // const ok = this.activeThumbSlide % l === index % l
      // return ok

      // just center
      const real = this.startSlide + this.activeThumbSlide;
      return real === index;
    },
    getStyle(item, index) {
      return {
        width: this.measureThumb(item) + "px",
        transition: `margin ${this.transformDuration * 0.66}ms ease-in-out`,
        margin: this.isSlideActive(index) // && this.isResting && !this.parentActive
          ? "0 " + this.padding + "px"
          : 0
      };
    }
  }
};
</script>
