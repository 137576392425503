var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.theme)?_c('div',{staticClass:"z-10 flex items-center justify-center leading-tight pointer-events-none header-row-height"},[_c('div',{staticClass:"block h-full rounded-lg",style:({
      width: _vm.width ? _vm.width + 'px' : '100px',
      backdropFilter: _vm.theme.show_glass ? 'blur(96px)' : 'none',
      background: !_vm.theme.show_glass
        ? _vm.theme.bar_bgcolor
        : 'rgba(13,13,13,.25)',
      transition: ("all " + _vm.speed + "ms cubic-bezier(.16,1,.3,1)"),
      willChange: 'transform, width'
    })}),_c('div',{staticClass:"absolute left-0 flex items-center rounded-lg justify-items-center",style:({
      width: _vm.tablet.width + 'px',
      opacity: _vm.tablet.width > 0 ? _vm.tablet.opacity : 0,
      height: 'calc(100% - 2px)',
      backdropFilter: _vm.theme.show_glass ? 'blur(96px)' : 'none',
      background: 'rgba(255, 255, 255, 0.15)',
      transform: ("translateX(" + (_vm.tablet.translateX) + "px)"),
      willChange: 'transform, width',
      transition: ("opacity .6s cubic-bezier(.16,1,.3,1), transform " + _vm.speed + "ms cubic-bezier(.16,1,.3,1)")
    })})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }