<template lang="pug">
  img.transition-opacity.duration-200(:src="thumb", :alt="alt", @error="onError", @load="onLoad", :class="loaded ? '' : 'opacity-0'")
</template>

<script>
import { thumbSrc } from '@/utils'
export default {
  name: 'RespImg',
  props: ['src', 'alt', 'byHeight'],
  data () {
    return {
      length: 0,
      thumb: '',
      loaded: false
    }
  },
  methods: {
    measure () {
      this.length = this.byHeight ? this.$el.offsetHeight : this.$el.offsetWidth
    },
    setThumb () {
      this.thumb = thumbSrc(this.src, this.length, this.byHeight)
    },
    onLoad () {
      this.$emit('load')
      this.$store.state.bus.$emit('imgloaded')
      this.loaded = true
    },
    onError () {
      console.warn('RespImg @error - loading master')
      this.thumb = this.src
      this.loaded = true
    }
  },
  mounted () {
    this.measure()
    this.setThumb()
  }
}
</script>
