<template lang="pug">
  .slice--subscribe.text-24.md-text-44.px-15.py-30.md-px-30(:style="{backgroundColor: slice.primary.bg_color, color: slice.primary.text_color}")
    subscribe-form
</template>

<script>
import SubscribeForm from "@/components/SubscribeForm";
export default {
  name: "Slice--Subscribe",
  props: ["slice"],
  components: { SubscribeForm }
};
</script>

<style></style>
